<template>
  <div
    :class="{
      'blur-content': this.isModalActive,
    }"
    class="h-100"
  >
    <div>
      <ProjectBreadcrumbComponent
        :options="[
          { name: 'Tâches', id: 'project.tasks' },
          { name: 'Compte-rendus', id: 'project.monitoring_new' },
          {
            name: 'Avancement',
            type: 'link',
            url: this.project?.betaSpreadsheet,
            disabled: !this.project?.betaSpreadsheet,
          },
        ]"
        :project_id="$route.params.id"
        current_route="project.tasks"
        title="Tâches"
      ></ProjectBreadcrumbComponent>
    </div>

    <button class="btn btn-primary btn-sm" @click="createReport">Nouveau compte-rendu</button>

    <!-- IMPORTANT INFO -->
    <div class="row g-5 mt-2 mb-5">
      <div class="col-12 print-hidden">
        <div class="card">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3">Informations importantes</span>
            </h3>
            <div class="d-flex align-middle">
              <button
                class="btn btn-primary py-2 my-auto btn-active-accent align-content-center"
                @click="this.updateProjectProperties()"
              >
                Enregistrer
                <span
                  v-if="this.editProject.buttonProjectPropertiesLoading"
                  class="ms-1 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </span>
              </button>
            </div>
          </div>
          <div class="card-body w-100 row g-0 pt-2">
            <div class="col-12">
              <div class="form-group w-100 h-100 pb-3 mb-3">
                <textarea
                  v-model="this.projectProperties.importantInfo"
                  class="form-control form-control-lg form-control-solid w-100"
                  style="height: 100%"
                  type="text"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TOOLBAR -->
    <div class="d-flex flex-column align-items-center justify-content-between">
      <div class="card-toolbar align-self-center d-flex flex-wrap w-100">
        <div v-if="getCollapsibleItems()?.length" class="dropdown">
          <button aria-expanded="false" class="btn btn-secondary btn-icon" data-bs-toggle="dropdown" type="button">
            <i class="bi bi-justify"></i>
          </button>
          <ul class="dropdown-menu px-2">
            <li v-for="item in getCollapsibleItems()" :key="item.id" class="">
              <a class="dropdown-item rounded rounded-top" @click="this.scrollToSection(`#collapse-${item.id}`)">{{
                item.name
              }}</a>
            </li>
          </ul>
        </div>
        <div class="ms-1 ms-lg-auto d-flex ms-xs-auto ms-auto me-auto me-lg-0">
          <ul class="nav nav-pills nav-light">
            <li class="nav-item m-0">
              <button
                :class="{
                  active: showByLot,
                  'border-hover-secondary': !showByLot,
                }"
                class="btn-active-primary btn btn-color-muted rounded-end-0 border border-1 border-dashed border-primary py-2"
                @click="showByLotClick"
              >
                Par lot
              </button>
            </li>
            <li class="nav-item m-0">
              <button
                :class="{
                  active: showByLocation,
                  'border-hover-secondary': !showByLocation,
                }"
                class="btn-active-primary btn btn-color-muted rounded-start-0 border border-1 border-dashed border-primary py-2"
                @click="showByLocationClick"
              >
                Par emplacement
              </button>
            </li>
          </ul>
        </div>
        <div class="ms-auto d-flex">
          <button class="btn btn-primary me-2" @click="this.modals.sort.show = !this.modals.sort.show">
            <span class="bi bi-sort-alpha-down"></span>
          </button>
          <input
            v-model="this.taskResearchFilter"
            class="form-control form-control-solid bg-white ms-3"
            placeholder="Rechercher"
          />
        </div>
      </div>
    </div>

    <!-- TOP OF TASKS SELECTION CONTEXTUAL TOOLBAR -->
    <div v-if="this.tasks.length" class="d-flex justify-content-end mt-3">
      <div class="d-flex flex-column align-items-end">
        <div class="d-flex">
          <label class="ms-3" for="selectAllTasks">Sélectionner toutes les tâches</label>
          <input
            id="selectAllTasks"
            :checked="this.selectedAllLots.length > 0 && this.selectedAllLots.length === this.tasks.length"
            :indeterminate="this.selectedAllLots.length > 0 && this.selectedAllLots.length < this.tasks.length"
            class="form-check-input ms-2 me-9"
            type="checkbox"
            @click="selectAll"
          />
        </div>
        <div v-if="this.selectedAllLots.length !== 0" class="d-flex ms-3 mt-2">
          <DeleteButton
            tooltip-name="Supprimer les tâches sélectionnées"
            @click="deleteTaskConfirm(selectedAllLots, true)"
          ></DeleteButton>
          <EditButton
            tooltip-name="Modifier les tâches sélectionnées"
            @click="modalBatchUpdateTaskShow(this.selectedAllLots)"
          ></EditButton>
        </div>
      </div>
    </div>

    <!-- FIXED BOTTOM SELECTION CONTEXTUAL TOOLBAR -->
    <div v-if="this.selectedAllLots.length !== 0" class="fixed-bottom bg-primary z-0">
      <div class="d-flex justify-content-between ms-3 my-2 h-100 py-2">
        <div class="mb-0 ps-3 d-flex align-items-center text-white">
          <span class="fw-bolder me-2">{{ this.selectedAllLots.length }}</span> tâche{{
            this.selectedAllLots.length > 1 ? "s" : ""
          }}
          sélectionnée{{ this.selectedAllLots.length > 1 ? "s" : "" }}
        </div>
        <div class="mb-0 pe-3">
          <DeleteButton
            tooltip-name="Supprimer les tâches sélectionnées"
            @click="deleteTaskConfirm(selectedAllLots, true)"
          ></DeleteButton>
          <EditButton
            tooltip-name="Modifier les tâches sélectionnées"
            @click="modalBatchUpdateTaskShow(this.selectedAllLots)"
          ></EditButton>
        </div>
      </div>
    </div>

    <!-- TASKS -->
    <div v-if="loading">
      <div class="d-flex flex-column justify-content-center align-items-center text-center h-300px">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span class="text-muted mt-3">Chargement des tâches...</span>
      </div>
    </div>
    <div v-else>
      <!-- GENERAL TASKS -->
      <div>
        <CollapsibleSection
          :key="recompute"
          :items="generalTasks"
          :selected-all-lots-ids="this.selectedAllLotsIds"
          viewType="lot"
          @addTask="(item) => createTask(item, 'lot')"
          @delete="deleteTaskConfirm"
          @duplicate="duplicateTask"
          @edit="editTask"
          @select="handleSelection"
        >
        </CollapsibleSection>
      </div>

      <!-- TASKS BY LOT -->
      <div v-if="showByLot">
        <CollapsibleSection
          :key="recompute"
          :items="groupedLots"
          :selected-all-lots-ids="this.selectedAllLotsIds"
          viewType="lot"
          @addTask="(item) => createTask(item, 'lot')"
          @delete="deleteTaskConfirm"
          @duplicate="duplicateTask"
          @edit="editTask"
          @select="handleSelection"
        >
        </CollapsibleSection>
      </div>

      <!-- TASKS BY LOT - EMPTY STATE -->
      <div v-if="showByLot && !groupedLots?.length">
        <TasksEmptyState @createTask="createTask"></TasksEmptyState>
      </div>

      <!-- TASKS BY LOCATION -->
      <div v-if="showByLocation">
        <CollapsibleSection
          :key="recompute"
          :items="groupedLocations"
          :selected-all-lots-ids="this.selectedAllLotsIds"
          viewType="location"
          @addTask="(item) => createTask(item, 'location')"
          @delete="deleteTaskConfirm"
          @duplicate="duplicateTask"
          @edit="editTask"
          @select="handleSelection"
        >
        </CollapsibleSection>
      </div>

      <!-- TASKS BY LOCATION - EMPTY STATE -->
      <div v-if="showByLocation && !groupedLocations?.length">
        <TasksEmptyState @createTask="createTask"></TasksEmptyState>
      </div>
    </div>

    <!-- BOTTOM - IMPORT TASKS MENU -->
    <div class="col-12 mt-10 print-hidden">
      <!--begin::Card-->
      <div class="card card-stretch h-100 mh-100 p-2 flex-grow-1">
        <!--begin::Body-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">
              <i class="bi fs-3 me-2 bi-gear-wide-connected"></i>Synchronisation descriptif &lt;&gt; chantier
            </span>
          </h3>
        </div>
        <div class="card-body pt-3 mt-n3 align-content-center d-flex">
          <div class="align-self-center my-auto">
            <label class="me-2">Importer mon descriptif dans l'app de suivi de chantier :</label>
            <button class="btn btn-light btn-active-accent fw-bolder" @click="this.modalUploadTaskShow()">
              Lancer l'import
              <span v-if="this.modals.uploadTasks.loading" class="ms-2 spinner-border spinner-border-sm"></span>
            </button>
          </div>
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card-->
    </div>
  </div>
  <div>
    <div
      :style="this.modals.sort.show ? 'display: block;' : 'display: none;'"
      class="modal"
      tabindex="-1"
      @keydown.esc="this.modalSortClose()"
    >
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalSortClose()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <div class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Tri des tâches</h3>
                        </div>
                        <!--end::Heading-->

                        <div class="mt-2">
                          <div class="form-check">
                            <input
                              id="checkHideArchived"
                              v-model="this.modals.sort.hideArchived"
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <label class="form-check-label" for="checkHideArchived">
                              Masquer les tâches archivées
                            </label>
                          </div>
                        </div>

                        <div class="mt-4">
                          <label class="form-label">Trier par :</label>
                          <select v-model="this.modals.sort.sort1" class="form-select">
                            <option value="">Tri par défaut</option>
                            <option value="status__order">Statut (croissant)</option>
                            <option value="-status__order">Statut (décroissant)</option>
                            <option value="start_date">Date de début (croissante)</option>
                            <option value="-start_date">Date de début (décroissante)</option>
                            <option value="end_date">Date de fin (croissante)</option>
                            <option value="-end_date">Date de fin (décroissante)</option>
                            <option value="assigned_company__name">Entreprise (croissante)</option>
                            <option value="-assigned_company__name">Entreprise (décroissante)</option>
                            <option value="updated_date">Dernière modif (croissante)</option>
                            <option value="-updated_date">Dernière modif (décroissante)</option>
                            <option value="created_time">Date de création (croissante)</option>
                            <option value="-created_time">Date de création (décroissante)</option>
                            <option value="numero">Numéro de l'ouvrage (croissant)</option>
                            <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                            <option value="title">Titre de l'ouvrage (croissant)</option>
                            <option value="-title">Titre de l'ouvrage (décroissant)</option>
                            <option value="order">Ordre personnalisé (croissant)</option>
                            <option value="-order">Ordre personnalisé (décroissant)</option>
                          </select>
                        </div>

                        <div class="mt-2">
                          <label class="form-label">Puis par :</label>
                          <select v-model="this.modals.sort.sort2" class="form-select">
                            <option value="">Tri par défaut</option>
                            <option value="status__order">Statut (croissant)</option>
                            <option value="-status__order">Statut (décroissant)</option>
                            <option value="start_date">Date de début (croissante)</option>
                            <option value="-start_date">Date de début (décroissante)</option>
                            <option value="end_date">Date de fin (croissante)</option>
                            <option value="-end_date">Date de fin (décroissante)</option>
                            <option value="assigned_company__name">Entreprise (croissante)</option>
                            <option value="-assigned_company__name">Entreprise (décroissante)</option>
                            <option value="updated_date">Dernière modif (croissante)</option>
                            <option value="-updated_date">Dernière modif (décroissante)</option>
                            <option value="created_time">Date de création (croissante)</option>
                            <option value="-created_time">Date de création (décroissante)</option>
                            <option value="numero">Numéro de l'ouvrage (croissant)</option>
                            <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                            <option value="title">Titre de l'ouvrage (croissant)</option>
                            <option value="-title">Titre de l'ouvrage (décroissant)</option>
                            <option value="order">Ordre personnalisé (croissant)</option>
                            <option value="-order">Ordre personnalisé (décroissant)</option>
                          </select>
                        </div>

                        <div class="mt-2">
                          <label class="form-label">Puis par :</label>
                          <select v-model="this.modals.sort.sort3" class="form-select">
                            <option value="">Tri par défaut</option>
                            <option value="status__order">Statut (croissant)</option>
                            <option value="-status__order">Statut (décroissant)</option>
                            <option value="start_date">Date de début (croissante)</option>
                            <option value="-start_date">Date de début (décroissante)</option>
                            <option value="end_date">Date de fin (croissante)</option>
                            <option value="-end_date">Date de fin (décroissante)</option>
                            <option value="assigned_company__name">Entreprise (croissante)</option>
                            <option value="-assigned_company__name">Entreprise (décroissante)</option>
                            <option value="updated_date">Dernière modif (croissante)</option>
                            <option value="-updated_date">Dernière modif (décroissante)</option>
                            <option value="created_time">Date de création (croissante)</option>
                            <option value="-created_time">Date de création (décroissante)</option>
                            <option value="numero">Numéro de l'ouvrage (croissant)</option>
                            <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                            <option value="title">Titre de l'ouvrage (croissant)</option>
                            <option value="-title">Titre de l'ouvrage (décroissant)</option>
                            <option value="order">Ordre personnalisé (croissant)</option>
                            <option value="-order">Ordre personnalisé (décroissant)</option>
                          </select>
                        </div>

                        <div class="mt-2">
                          <label class="form-label">Puis par :</label>
                          <select v-model="this.modals.sort.sort4" class="form-select">
                            <option value="">Tri par défaut</option>
                            <option value="status__order">Statut (croissant)</option>
                            <option value="-status__order">Statut (décroissant)</option>
                            <option value="start_date">Date de début (croissante)</option>
                            <option value="-start_date">Date de début (décroissante)</option>
                            <option value="end_date">Date de fin (croissante)</option>
                            <option value="-end_date">Date de fin (décroissante)</option>
                            <option value="assigned_company__name">Entreprise (croissante)</option>
                            <option value="-assigned_company__name">Entreprise (décroissante)</option>
                            <option value="updated_date">Dernière modif (croissante)</option>
                            <option value="-updated_date">Dernière modif (décroissante)</option>
                            <option value="created_time">Date de création (croissante)</option>
                            <option value="-created_time">Date de création (décroissante)</option>
                            <option value="numero">Numéro de l'ouvrage (croissant)</option>
                            <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                            <option value="title">Titre de l'ouvrage (croissant)</option>
                            <option value="-title">Titre de l'ouvrage (décroissant)</option>
                            <option value="order">Ordre personnalisé (croissant)</option>
                            <option value="-order">Ordre personnalisé (décroissant)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->

              <!--begin::Actions-->
              <div class="d-flex justify-content-end">
                <div>
                  <button class="btn btn-lg btn-light" @click="modalSortClose()">Annuler</button>
                  <button class="btn btn-lg btn-primary ms-2" @click="modalSortValidate()">Enregistrer</button>
                </div>
              </div>
              <!--end::Actions-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteTaskConfirmModal
      v-if="showDeleteConfirmModal"
      :project-id="currentProjectId"
      :task-id="selectedTask.id"
      @close="closeDeleteTaskConfirm"
      @validate="validateDeleteTaskConfirm"
    >
    </DeleteTaskConfirmModal>
    <TaskForm
      v-if="showTaskFormModal"
      :initial-location-id="selectedLocationId"
      :initial-lot-id="selectedLotId"
      :initial-project-id="currentProjectId"
      :initial-status-id="defaultStatus"
      :task="selectedTask"
      @close="closeTaskFormModal"
      @refetchTasks="refetchTasks"
    >
    </TaskForm>
    <ReportForm
      v-if="showReportFormModal"
      :initial-project-id="currentProjectId"
      :initialReportNumber="getMaxReportNumber ? getMaxReportNumber + 1 : getMaxReportNumber"
      :taskSortOrder="this.taskSortOrder"
      @close="closeReportFormModal"
    >
    </ReportForm>
    <!-- MODAL - CONFIRM UPLOAD TASKS-->
    <UploadTasksModal
      v-if="this.modals.uploadTasks.show"
      v-model="this.modals.uploadTasks.lots"
      :currentProjectId="this.currentProjectId"
      :lots="this.currentProject.lots"
      @close="modalUploadTaskClose()"
      @validate="modalUploadTaskValidate()"
    >
    </UploadTasksModal>
    <!-- MODAL - BATCH UPDATE TASKS-->
    <BatchUpdateTaskModal
      v-if="this.modals.batchUpdate.show"
      :tasks="this.selectedAllLots"
      @close="modalBatchUpdateTaskClose()"
      @validate="modalBatchUpdateTaskValidate()"
    >
    </BatchUpdateTaskModal>
  </div>
</template>

<script>
import { CURRENT_USER_QUERY, GET_PROJECT_BY_ID, MUTATION_PROJECT_UPDATE } from "@/graphql/graphql";
import kalmStore from "@/store";
import DeleteButton from "@/views/_core/components/DeleteButton.vue";
import EditButton from "@/views/_core/components/EditButton.vue";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent.vue";
import { GET_REPORTS } from "@/views/reports/data/reports_graphql";
import ReportForm from "@/views/reports/presentation/report-form/ReportForm.vue";
import { ALL_STATUSES_QUERY } from "@/views/status/data/status_graphql";
import UploadTasksModal from "@/views/tasks/presentation/tasks/components/UploadTasksModal.vue";
import { toast } from "vue3-toastify";
import { CREATE_TASK, GET_TASKS } from "../../data/tasks_graphql";
import TaskForm from "../task-form/TaskForm.vue";
import BatchUpdateTaskModal from "./components/BatchUpdateTaskModal.vue";
import CollapsibleSection from "./components/CollapsibleSection.vue";
import DeleteTaskConfirmModal from "./components/DeleteTaskConfirmModal.vue";
import TasksEmptyState from "./components/TasksEmptyState.vue";

export default {
  name: "TasksPage",
  components: {
    ProjectBreadcrumbComponent,
    CollapsibleSection,
    TasksEmptyState,
    DeleteTaskConfirmModal,
    TaskForm,
    ReportForm,
    UploadTasksModal,
    DeleteButton,
    EditButton,
    BatchUpdateTaskModal,
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      result({ data }) {
        this.modals.sort.sort1 = data.currentUser.org.persoTaskSort1;
        this.modals.sort.sort2 = data.currentUser.org.persoTaskSort2;
        this.modals.sort.sort3 = data.currentUser.org.persoTaskSort3;
        this.modals.sort.sort4 = data.currentUser.org.persoTaskSort4;
        this.modals.sort.hideArchived = data.currentUser.org.persoTaskHideArchived;
      },
    },
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: this.currentProjectId,
        };
      },
      skip() {
        return !(this.currentProjectId && this.currentProjectId !== "0" && this.currentProjectId !== 0);
      },
    },
    statuses: {
      query: ALL_STATUSES_QUERY,
    },
    tasks: {
      query: GET_TASKS,
      variables() {
        return {
          projectId: this.currentProjectId,
          taskSortOrder: this.taskSortOrder,
          hideArchived: this.modals.sort.hideArchived,
        };
      },
      skip() {
        return !this.currentProjectId;
      },
    },
    reports: {
      query: GET_REPORTS,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          projectId: this.currentProjectId,
        };
      },
      skip() {
        return !this.currentProjectId;
      },
    },
  },
  data() {
    return {
      recompute: 0,
      project: {},
      tasks: [],
      modals: {
        sort: {
          show: false,
          hideArchived: false,
          sort1: null,
          sort2: null,
          sort3: null,
          sort4: null,
        },
        uploadTasks: {
          show: false,
          lots: [],
        },
        batchUpdate: {
          show: false,
          tasks: [],
        },
      },
      editProject: {
        buttonProjectPropertiesLoading: false,
        importantInfo: "",
      },
      taskResearchFilter: "",
      showByLot: true,
      showByLocation: false,
      showDeleteConfirmModal: false,
      showTaskFormModal: false,
      showReportFormModal: false,
      selectedTask: undefined,
      selectedLocationId: undefined,
      selectedLotId: undefined,
      selectedLots: [],
    };
  },
  computed: {
    loading() {
      return this.$apollo.queries?.tasks?.loading && this.tasks.length === 0;
    },
    taskSortOrder() {
      return [this.modals.sort.sort1, this.modals.sort.sort2, this.modals.sort.sort3, this.modals.sort.sort4].filter(
        (s) => s
      );
    },
    getMaxReportNumber() {
      try {
        return this.reports.reduce((max, report) => (report?.reportNumber > max ? report?.reportNumber : max), 0);
      } catch {
        return null;
      }
    },
    currentProjectId() {
      return kalmStore.getters.currentProjectFocus;
    },
    isModalActive() {
      return (
        this.showDeleteConfirmModal | this.showTaskFormModal | this.showReportFormModal ||
        Object.values(this.modals).some((m) => m.show)
      );
    },
    generalTasks() {
      const groupedLots = {};

      function researchFilter(task = {}, taskResearchFilter = "") {
        return (
          (task?.title || "").toLowerCase().includes(taskResearchFilter.toLowerCase()) ||
          (task?.description || "").toLowerCase().includes(taskResearchFilter.toLowerCase()) ||
          (task?.numero || "").toLowerCase().includes(taskResearchFilter.toLowerCase())
        );
      }

      if (this.tasks && this.tasks.length > 0) {
        groupedLots[0] = {
          id: null,
          name: "Tâches générales",
          tasks: [],
        };

        this.tasks
          .filter((t) => !t?.lot)
          .filter((t) => researchFilter(t, this.taskResearchFilter))
          .forEach((task) => {
            groupedLots[0].tasks.push(task);
          });
      }

      return Object.values(groupedLots).sort((a, b) => a.number.localeCompare(b.number, undefined, { numeric: true }));
    },
    groupedLots() {
      const groupedLots = {};

      function researchFilter(task = {}, taskResearchFilter = "") {
        return (
          (task?.title || "").toLowerCase().includes(taskResearchFilter.toLowerCase()) ||
          (task?.description || "").toLowerCase().includes(taskResearchFilter.toLowerCase()) ||
          (task?.numero || "").toLowerCase().includes(taskResearchFilter.toLowerCase()) ||
          (task?.lot?.fullName || "").toLowerCase().includes(taskResearchFilter.toLowerCase())
        );
      }

      if (this.tasks && this.tasks.length > 0) {
        this.tasks
          .filter((t) => t?.lot)
          .filter((t) => researchFilter(t, this.taskResearchFilter))
          .forEach((task) => {
            const lotId = task.lot.id;

            if (!groupedLots[lotId]) {
              groupedLots[lotId] = {
                id: lotId,
                name: task.lot.fullName,
                number: task.lot.number,
                tasks: [],
              };
            }
            groupedLots[lotId].tasks.push(task);
          });
      }

      return Object.values(groupedLots).sort((a, b) => a.number.localeCompare(b.number, undefined, { numeric: true }));
    },
    groupedLocations() {
      const groupedLocations = {};

      if (this.tasks && this.tasks.length > 0) {
        this.tasks.forEach((task) => {
          task.locations.forEach((location) => {
            const locationId = location.id;

            if (!groupedLocations[locationId]) {
              groupedLocations[locationId] = {
                id: locationId,
                name: location.name,
                tasks: [],
              };
            }
            groupedLocations[locationId].tasks.push(task);
          });
        });
      }

      // Convert the groupedLocations object to an array
      return Object.values(groupedLocations).sort((a, b) => a.name.localeCompare(b.name));
    },
    currentProject() {
      return this.project;
    },
    selectedAllLots: {
      get() {
        return this.selectedLots;
      },
      set(newValue) {
        this.selectedLots = newValue;
      },
    },
    projectProperties: {
      get() {
        return {
          importantInfo: this.editProject.importantInfo || this.currentProject.importantInfo,
        };
      },
      set(newValue) {
        this.editProject = newValue;
      },
    },
    selectedAllLotsIds() {
      return this.selectedAllLots?.map((task) => task.id);
    },
    defaultStatus() {
      return this.statuses.find((status) => status.name === "À FAIRE")?.id;
    },
  },
  watch: {
    $route(to) {
      this.projectId = to.params.id;
    },
    "selectedAllLots.length": {
      // hide the tawkTo chat when there is a task selection in progress
      handler(newValue) {
        if (window.Intercom) {
          if (newValue === 0) {
            window.Intercom("update", {
              hide_default_launcher: false,
            });
          } else {
            window.Intercom("update", {
              hide_default_launcher: true,
            });
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    getCollapsibleItems() {
      return this.showByLocation ? this.groupedLocations : this.groupedLots;
    },
    scrollToSection(hash) {
      const element = document.querySelector(hash);
      if (element) {
        window.scrollTo({
          top: element.offsetTop + 150,
          behavior: "smooth",
        });
      } else {
        console.log("element DOES NOT EXIST");
      }
    },
    selectAll() {
      if (this.selectedAllLots.length === this.tasks.length) {
        this.selectedAllLots = [];
      } else {
        this.selectedAllLots = [...this.tasks];
      }
    },
    handleSelection(selected) {
      if (this.selectedAllLots.includes(selected)) {
        this.selectedAllLots = this.selectedAllLots.filter((task) => task !== selected);
      } else {
        this.selectedAllLots.push(selected);
      }
    },
    modalBatchUpdateTaskShow(tasks) {
      this.modals.batchUpdate.tasks = tasks;
      this.modals.batchUpdate.show = true;
    },
    modalBatchUpdateTaskClose() {
      this.modals.batchUpdate.show = false;
      this.selectedAllLots = [];
    },
    modalBatchUpdateTaskValidate() {
      this.refetchTasks();
      this.modals.batchUpdate.show = false;
      this.selectedAllLots = [];
    },
    refetchTasks() {
      this.$apollo.queries.tasks.refetch();
    },
    modalSortClose() {
      this.modals.sort.show = false;
    },
    modalSortValidate() {
      this.$apollo.queries.tasks.refetch();
      this.modalSortClose();
    },
    modalUploadTaskShow() {
      this.modals.uploadTasks.lots = [...this.currentProject.lots.map((l) => l.id)];
      this.modals.uploadTasks.show = true;
    },
    modalUploadTaskValidate() {
      this.modalUploadTaskClose();
      this.refetchTasks();
    },
    modalUploadTaskClose() {
      this.modals.uploadTasks.show = false;
    },
    async duplicateTask(duplicateFrom) {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_TASK,
          variables: {
            input: {
              projectId: this.currentProjectId,
            },
            duplicateFrom: duplicateFrom.id,
          },
          update: () => {
            this.refetchTasks();
          },
        });
      } catch (error) {
        console.error("Duplicate task error:", error);
      }
    },
    showByLotClick() {
      this.showByLocation = false;
      this.showByLot = true;
    },
    showByLocationClick() {
      this.showByLocation = true;
      this.showByLot = false;
    },
    editTask(task) {
      this.selectedTask = task;
      this.showTaskFormModal = true;
    },
    createTask(item, itemType) {
      this.selectedLocationId = undefined;
      this.selectedLotId = undefined;

      // noinspection EqualityComparisonWithCoercionJS
      if (itemType == "location") {
        this.selectedLocationId = item.id;
      } else {
        // noinspection EqualityComparisonWithCoercionJS
        if (itemType == "lot") {
          this.selectedLotId = item.id;
        }
      }
      this.selectedTask = undefined;
      this.showTaskFormModal = true;
    },
    closeTaskFormModal() {
      this.$apollo.queries.tasks.refetch();
      this.showTaskFormModal = false;
    },
    createReport() {
      this.showReportFormModal = true;
    },
    closeReportFormModal() {
      this.showReportFormModal = false;
    },
    deleteTaskConfirm(task, all = false) {
      if (all) {
        this.selectedTask = { id: task.map((t) => t.id) };
      } else {
        this.selectedTask = task;
      }
      this.showDeleteConfirmModal = true;
    },
    closeDeleteTaskConfirm() {
      this.selectedTask = undefined;
      this.showDeleteConfirmModal = false;
    },
    validateDeleteTaskConfirm() {
      this.refetchTasks();
      this.selectedAllLots = [];
    },
    async updateProjectProperties() {
      this.editProject.buttonProjectPropertiesLoading = true;

      setTimeout(() => {
        this.editProject.buttonProjectPropertiesLoading = false;
      }, 350);

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_PROJECT_UPDATE,
          variables: {
            projectId: kalmStore.getters.currentProjectFocus,
            newInfoImportantInfo: this.projectProperties.importantInfo,
          },
          refetchQueries: [
            {
              query: GET_PROJECT_BY_ID,
              variables: {
                id: this.currentProjectId,
              },
            },
          ],
        });
        toast.success("Les informations ont été mises à jour.");
      } catch (error) {
        console.error("Update project properties error:", error);
        toast.error("Une erreur est survenue lors de la mise à jour des informations.");
      }
    },
  },
};
</script>

<style scoped>
.loading-blur {
  filter: blur(5px);
}

.loading-spinner {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
