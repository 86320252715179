<template>
  <KalmModal modalTitle="Photos & pièces-jointes" @close="close">
    <template v-slot:body>
      <div class="">
        <label class=""
          >Disposition des fichiers
          <span v-tooltip:bottom="'Nombre de fichiers par ligne'" class="ms-1 bi bi-info-circle"></span>
        </label>
        <div>
          <input id="layoutCheckbox1" v-model="this.layout" :value="1" class="btn-check" type="radio" />
          <label class="btn btn-sm rounded-end-0 btn-light" for="layoutCheckbox1" title="Largeur complète">
            1 <span class="ms-2 bi bi-square"></span>
          </label>
          <input id="layoutCheckbox2" v-model="this.layout" :value="2" class="btn-check" type="radio" />
          <label class="btn btn-sm rounded-0 btn-light" for="layoutCheckbox2" title="2 par ligne">
            2 <span class="ms-2 bi bi-grid"></span>
          </label>
          <input id="layoutCheckbox3" v-model="this.layout" :value="3" class="btn-check" type="radio" />
          <label class="btn btn-sm rounded-start-0 btn-light" for="layoutCheckbox3" title="3 par ligne">
            3 <span class="ms-2 bi bi-grid-3x2-gap"></span>
          </label>
        </div>
      </div>
      <div class="mt-4 mb-10">
        <label>Ajouter un fichier :</label>
        <input ref="fileForTaskFileInput" class="form-control" multiple type="file" @change="handleFileChange" />
      </div>
      <div v-if="task.files.length > 0">
        <p class="fw-bold text-gray-700">Fichiers liées à la tâche :</p>

        <div v-if="!this.isLoading" class="d-row text-center justify-content-center g-10">
          <div
            v-for="file in task.files"
            :key="file.id"
            class="position-relative d-inline-block m-5"
            style="height: 150px; max-width: 150px"
          >
            <!-- Display an image if the file is an image -->
            <img
              v-if="isImage(file)"
              :src="buildFullUrl(file.url)"
              alt="Fichier non décrit inclue sur la tâche pour le CR"
              class="rounded shadow shadow-xs h-100 w-100"
            />

            <!-- Display a file icon if the file is not an image -->
            <div
              v-else
              class="d-flex flex-column align-items-center justify-content-center rounded shadow shadow-xs h-100 w-100 text-center text-muted"
            >
              <i class="bi bi-file-earmark-text fs-1"></i>
              <p :title="file.name" class="text-truncate mt-2 mb-0 w-75">{{ file.name }}</p>
            </div>

            <!-- Delete button positioned at the top-right corner of each card -->
            <button
              class="btn btn-icon btn-sm btn-primary position-absolute top-0 end-0 translate-middle-y me-n4"
              @click="this.removeFileFromTask(file.id)"
            >
              <span class="bi bi-x"></span>
            </button>

            <!-- Open file in new tab -->
            <button
              class="btn btn-icon btn-sm btn-primary position-absolute top-0 start-0 translate-middle-y ms-n4"
              @click="this.downloadFileFromTask(file.url)"
            >
              <span class="bi bi-download"></span>
            </button>
          </div>
        </div>
        <div v-else class="mt-4 mb-10 absolute">
          <div class="spinner-border"></div>
        </div>
      </div>
      <div v-else class="">
        <p>Aucun fichier liée à cette tâche</p>
      </div>
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import { FILE_UPLOAD, UNASSIGN_TASK } from "@/views/documents/data/files_graphql";
import { GET_TASK_BY_ID, UPDATE_TASK } from "@/views/tasks/data/tasks_graphql";

export default {
  name: "FilesForTaskModal",
  apollo: {
    task: {
      query: GET_TASK_BY_ID,
      variables() {
        return {
          taskId: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
  data() {
    return {
      task: {
        files: [],
      },
      isLoading: false,
    };
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  components: {
    KalmModal,
  },
  methods: {
    isImage(file) {
      return (
        file.url.toLowerCase().endsWith(".png") ||
        file.url.toLowerCase().endsWith(".jpg") ||
        file.url.toLowerCase().endsWith(".jpeg") ||
        file.url.toLowerCase().endsWith(".gif")
      );
    },
    downloadFileFromTask(fileUrl) {
      window.open(this.buildFullUrl(fileUrl), "_blank");
    },
    removeFileFromTask(fileId) {
      this.$apollo.mutate({
        mutation: UNASSIGN_TASK,
        variables: {
          fileId: fileId,
          taskToRemoveId: this.taskId,
        },
        context: {
          hasUpload: true,
        },
        refetchQueries: [
          {
            query: GET_TASK_BY_ID,
            variables: {
              taskId: this.taskId,
            },
            skip() {
              return !this.taskId;
            },
          },
        ],
      });
    },
    close() {
      this.$emit("close");
    },
    buildFullUrl(url) {
      if (url.startsWith("http")) {
        return url;
      }
      return process.env.VUE_APP_URL_BACK + url;
    },
    openFileExplorer() {
      // Trigger the click event on the hidden file input
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      this.isLoading = true;
      const selectedFiles = event.target.files;
      selectedFiles.forEach((file, index) => {
        this.$apollo
          .mutate({
            mutation: FILE_UPLOAD,
            variables: {
              file,
              projectId: this.projectId,
              taskId: this.taskId,
              type: "MONITORING",
            },
            context: {
              hasUpload: true,
            },
            refetchQueries: [
              {
                query: GET_TASK_BY_ID,
                variables: {
                  taskId: this.taskId,
                },
                skip() {
                  return !this.taskId;
                },
              },
            ],
          })
          .then(() => {
            if (index === selectedFiles.length - 1) {
              this.isLoading = false;
              this.$refs.fileForTaskFileInput.value = "";
            }
          });
      });
    },
  },
  computed: {
    projectId() {
      return this.$store.getters.currentProjectFocus;
    },
    layout: {
      get() {
        return this.task.layout;
      },
      set(newValue) {
        console.log("newvalue", newValue);
        this.$apollo.mutate({
          mutation: UPDATE_TASK,
          variables: {
            id: this.taskId,
            input: {
              layout: newValue,
            },
          },
        });
      },
    },
  },
};
</script>
