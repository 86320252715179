<template>
  <div id="DeleteLotModal" class="modal" style="display: block" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="$emit('close')">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Supprimer le lot n°{{ getLotNumber }}</h3>
                        <p class="fw-bolder text-gray-700">{{ this.getLotName }}</p>
                        <p>Attention, cette suppression est définitive et effacera également :</p>
                        <ul>
                          <li>Les ouvrages et textes associés dans le decsriptif</li>
                          <li>Les prix et chiffrages dans le module de consultation</li>
                          <li>Les tâches associées dans le suivi de chantier</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" type="button" @click="$emit('close')">Annuler</button>
                      <button
                        id="DeleteButtonLotModal"
                        :disabled="!canSafelyDeleteLot"
                        class="btn btn-lg btn-danger ms-2"
                        type="button"
                        @click="validateDeleteLot"
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MUTATION_PROJECT_DELETE_LOT } from "@/views/lots/data/lots_graphql";

export default {
  name: "DeleteLotModal",
  props: {
    project: {
      type: Object,
      required: true,
    },
    lotId: {
      type: String,
      required: true,
    },
  },
  methods: {
    async validateDeleteLot() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_PROJECT_DELETE_LOT,
          variables: {
            projectId: this.project.id,
            lotId: this.lotId,
          },
        });
      } catch (e) {
        console.error(e);
      }
      this.$emit("validate");
    },
  },
  computed: {
    getLotName() {
      return this.project.lots.find((l) => l.id == this.lotId)?.fullName || "";
    },
    getLotNumber() {
      return this.project.lots.find((l) => l.id == this.lotId)?.number || "";
    },
    canSafelyDeleteLot() {
      // todo check can safely delete lot
      return true;
      /*// checks if there are registered ouvrages blocks or text blocks for the given lot
            let ret = true;

            if (this.lotId && this.project) {
                const doc = JSON.parse(this.project.document).doc;
                if (doc && doc.content) {
                    const lotContent = doc.content.find(n => n?.attrs?.id === this.lotId)
                        ?.content.find(n => n.type === "lotContent")?.content;

                    if (lotContent) {
                        ret = lotContent.every(n => {
                            return n.type !== "lotOuvrage" && n.type !== "lotText"
                        });
                    }
                }
            }
            return ret;*/
    },
  },
};
</script>

<style lang="scss" scoped></style>
